import React from "react";

import Layout from "../../components/layout";
import {GettingStartedSidebar as Sidebar} from "../../components/sidebar";
import {GettingStartedBreadcrumb as Breadcrumb} from "../../components/breadcrumb";
import {AskHowToInfoAlert, Callout} from "../../components/alert";
import {CodeSnippet} from "../../components/snippet";
import {ApiLink} from "../../components/link";
import {ConnectTutorialCode} from "../../components/code";
import {ServerBadgeList} from "../../components/servers";
import {KeyHeader} from "../../components/typography";

const pageTitle = "Connect to Database";

const Page = () => (
    <Layout pageTitle={pageTitle}
            sidebarNav={<Sidebar/>}
            mobileNav={<Breadcrumb activeName={pageTitle}/>}
    >
        <p>
            To connect to a database you should create and use a {' '}<ApiLink>SAConnection</ApiLink>{' '} object.
        </p>
        <p>
            For this tutorial we are going to connect to a local SQL Server database {' '}<code>demo</code>{' '} as
            a user {' '}<code>guest</code>{' '} with a password {' '}<code>secret</code>{' '} and then disconnect from
            it.
        </p>

        <KeyHeader>Connect to Database</KeyHeader>
        <p>
            The code below creates a connection object {' '}<code>con</code>{' '}
            represented by {' '}<ApiLink>SAConnection</ApiLink>{' '} class:
        </p>
        <ConnectTutorialCode
            className="mb-3"
            database="demo" user="guest" password="secret" client="SA_SQLServer_Client"
        />

        <div className="mb-3">
            The first parameter specifies the database, next two parameters specify the credentials,
            and the last one the type of the database connection is made to.
        </div>

        <Callout className="mb-3" heading="Remote Connection and Authentication" type="warning">
            <div>
                You can connect to remote servers using variety of protocols supported by each database server.
                You can also connect using different authentication mechanisms.
            </div>
            <details className="mt-2">
                <summary>Choose your server for additional info</summary>
                <div className="mt-2">
                    <ServerBadgeList linkClass="alert-link" linkType="connect" badgeBorder="warning"/>
                </div>
            </details>
            <div>

            </div>
        </Callout>

        <p>
            If you want to define the connection {' '}<code>con</code>{' '}
            as a connection to SQL Server database before calling
            {' '}<ApiLink>SAConnection::Connect</ApiLink>{' '} method use
            {' '}<ApiLink>SAConnection::setClient</ApiLink>{' '} method:
        </p>
        <CodeSnippet>{`
SAConnection con;
con.setClient( SA_SQLServer_Client );
con.Connect(_TSA("demo"), _TSA("guest"), _TSA("secret"));
            `.trim()}
        </CodeSnippet>

        <KeyHeader>Disconnect from Database</KeyHeader>
        <p>
            You can disconnect your connection object at any time with a call to the
            {' '}<ApiLink>SAConnection::Disconnect</ApiLink>{' '}
            method:
        </p>
        <CodeSnippet>con.Disconnect();</CodeSnippet>

        <p>
            It is safe to skip calling {' '}<ApiLink>SAConnection::Disconnect</ApiLink>{' '} method explicitly.
            Destructor {' '}<ApiLink>SAConnection::~SAConnection</ApiLink>{' '}
            will correctly close the connection if you didn't call
            {' '}<ApiLink>SAConnection::Disconnect</ApiLink>{' '} method before.
        </p>

        <AskHowToInfoAlert/>
    </Layout>
);

export default Page;
